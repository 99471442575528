import React from 'react'
import { EmbedNavigation } from '../../containers'
import { Container } from 'reactstrap'
import InstructorsSignupForm from '../../forms/instructors/InstructorsSignupForm'

function InstructorSignupView() {

    return (
        <>
            <EmbedNavigation text='Přihláška instruktora' type='instruktor' />
            <div className='push_from_nav application'>
                <Container>
                    <InstructorsSignupForm withRedirect={true} />
                </Container>
            </div>
        </>
    )

}

export default InstructorSignupView