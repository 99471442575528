import React, { Component } from "react";
import {
  Sidebar,
  SidebarNav,
  SidebarItem,
  SidebarDivider,
  SidebarHeader,
} from "../../styles";
import { NavLink } from "react-router-dom";

class SidebarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
    };
  }

  handleDropdown = () => this.setState({ dropdown: !this.state.dropdown });

  renderLinks = (userRole) => {
    switch (userRole) {
      case 4:
        return (
          <>
            <SidebarItem icon="\ec5e" iconColor="#5e72e4">
              <NavLink
                to={`${process.env.PUBLIC_URL}/parents/dashboard`}
                activeClassName="active"
              >
                Nástěnka
              </NavLink>
            </SidebarItem>
            <SidebarItem icon="\ed06" iconColor="#fb6340">
              <NavLink
                to={`${process.env.PUBLIC_URL}/parents/applications`}
                activeClassName="active"
              >
                Přihlášky
              </NavLink>
            </SidebarItem>
            <SidebarItem icon="\ecfd" iconColor="#11cdef">
              <NavLink
                to={`${process.env.PUBLIC_URL}/parents/profile/`}
                activeClassName="active"
              >
                Můj profil
              </NavLink>
            </SidebarItem>
          </>
        );
      case 3:
        return (
          <>
            <SidebarItem icon="\ec5e" iconColor="#5e72e4">
              <NavLink
                to={`${process.env.PUBLIC_URL}/employee/dashboard`}
                activeClassName="active"
              >
                Nástěnka
              </NavLink>
            </SidebarItem>
            <SidebarItem icon="\ec45" iconColor="#11cdef">
              <NavLink
                to={`${process.env.PUBLIC_URL}/employee/dates`}
                activeClassName="active"
              >
                Výběr termínů
              </NavLink>
            </SidebarItem>
            <SidebarItem icon="\ecfd" iconColor="#11cdef">
              <NavLink
                to={`${process.env.PUBLIC_URL}/employee/profile/`}
                activeClassName="active"
              >
                Můj profil
              </NavLink>
            </SidebarItem>
          </>
        );
      case 2:
        return (
          <>
            <SidebarItem icon="\ec45" iconColor="#11cdef">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/dates`}
                activeClassName="active"
              >
                Termíny
              </NavLink>
            </SidebarItem>
          </>
        );
      case 1:
        return (
          <>
            <SidebarItem icon="\ec5e" iconColor="#5e72e4">
              <NavLink
                to={`${process.env.PUBLIC_URL}/dashboard`}
                activeClassName="active"
              >
                Nástěnka
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\ed06" iconColor="#fb6340" dropdown>
              <span
                className={
                  this.state.dropdown ? "divider active" : "divider inactive"
                }
                onClick={this.handleDropdown}
              >
                Přihlášky
              </span>
              <ul
                className={
                  this.state.dropdown ? "dropdown active" : "dropdown inactive"
                }
              >
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/camp/0`}
                    activeClassName="active"
                  >
                    Tábory
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/camp/1`}
                    activeClassName="active"
                  >
                    Tábory zdarma
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/hobby`}
                    activeClassName="active"
                  >
                    Kroužky
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/kurzy`}
                    activeClassName="active"
                  >
                    Kurzy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/skolka`}
                    activeClassName="active"
                  >
                    Školka
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/instructors?state=1`}
                    activeClassName="active"
                  >
                    Instruktoři
                  </NavLink>
                </li>
              </ul>
            </SidebarItem>

            {/*
                        <SidebarItem icon='\ed06' iconColor='#fb6340'>
                            <NavLink to={`${process.env.PUBLIC_URL}/manage/applications/camp/0`} activeClassName='active'>Přihlášky - Tábory</NavLink>
                        </SidebarItem>
                        <SidebarItem icon='\ed06' iconColor='#f5365c'>
                            <NavLink to={`${process.env.PUBLIC_URL}/manage/applications/camp/1`} activeClassName='active'>Přihlášky - Tábory zdarma</NavLink>
                        </SidebarItem>
                        <SidebarItem icon='\ed06' iconColor='#2dce89'>
                            <NavLink to={`${process.env.PUBLIC_URL}/manage/applications/hobby`} activeClassName='active'>Přihlášky - Kroužky</NavLink>
                        </SidebarItem>
                        <SidebarItem icon='\ed06' iconColor='#2dce89'>
                            <NavLink to={`${process.env.PUBLIC_URL}/manage/applications/kurzy`} activeClassName='active'>Přihlášky - Kurzy</NavLink>
                        </SidebarItem>
                        <SidebarItem icon='\ed06' iconColor='#2dce89'>
                            <NavLink to={`${process.env.PUBLIC_URL}/manage/applications/skolka`} activeClassName='active'>Přihlášky - Školka</NavLink>
                        </SidebarItem>*/}

            <SidebarDivider />

            <SidebarItem icon="\ec45" iconColor="#11cdef">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/dates?date_state=1&type=1&year=2023`}
                activeClassName="active"
              >
                Termíny
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\ef79" iconColor="#2dce89">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/locations`}
                activeClassName="active"
              >
                Lokality
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\ee45" iconColor="#f5365c">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/camps`}
                activeClassName="active"
              >
                Tábory
              </NavLink>
            </SidebarItem>

            <SidebarDivider />

            <SidebarItem icon="\ee45" iconColor="#f5365c">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/employees`}
                activeClassName="active"
              >
                Instruktoři
              </NavLink>
            </SidebarItem>

            <SidebarDivider />

            <SidebarItem icon="\ec56">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/emails`}
                activeClassName="active"
              >
                Nastavení emailů
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\e964">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/invoices`}
                activeClassName="active"
              >
                Faktury a doklady
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\e964">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/increases`}
                activeClassName="active"
              >
                Zdražení
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\f005">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/vouchers`}
                activeClassName="active"
              >
                Vouchery
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\eef1">
              <NavLink
                to={`${process.env.PUBLIC_URL}/user/contacts`}
                activeClassName="active"
              >
                Kontakty
              </NavLink>
            </SidebarItem>

            <SidebarDivider />

            <SidebarItem icon="\efe1" dropdown>
              <span
                className={
                  this.state.dropdown ? "divider active" : "divider inactive"
                }
                onClick={this.handleDropdown}
              >
                Nastavení
              </span>
              <ul
                className={
                  this.state.dropdown ? "dropdown active" : "dropdown inactive"
                }
              >
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/organizations`}
                    activeClassName="active"
                  >
                    Správa organizací
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/users`}
                    activeClassName="active"
                  >
                    Správa uživatelů
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/settings/coupons`}
                    activeClassName="active"
                  >
                    Správa slevových kuponů
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/referrals`}
                    activeClassName="active"
                  >
                    Přehled doporučení
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/interests`}
                    activeClassName="active"
                  >
                    Nastavení zájmových položek
                  </NavLink>
                </li>
              </ul>
            </SidebarItem>
          </>
        );
      case 0:
        return (
          <>
            <SidebarItem icon="\ec5e" iconColor="#5e72e4">
              <NavLink
                to={`${process.env.PUBLIC_URL}/dashboard`}
                activeClassName="active"
              >
                Nástěnka
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\ed06" iconColor="#fb6340" dropdown>
              <span
                className={
                  this.state.dropdown ? "divider active" : "divider inactive"
                }
                onClick={this.handleDropdown}
              >
                Přihlášky
              </span>
              <ul
                className={
                  this.state.dropdown ? "dropdown active" : "dropdown inactive"
                }
              >
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/camp/0`}
                    activeClassName="active"
                  >
                    Tábory
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/camp/1`}
                    activeClassName="active"
                  >
                    Tábory zdarma
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/hobby`}
                    activeClassName="active"
                  >
                    Kroužky
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/kurzy`}
                    activeClassName="active"
                  >
                    Kurzy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/skolka`}
                    activeClassName="active"
                  >
                    Školka
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/instructors?state=1`}
                    activeClassName="active"
                  >
                    Instruktoři
                  </NavLink>
                </li>
              </ul>
            </SidebarItem>

            <SidebarDivider />

            <SidebarItem icon="\ee45" iconColor="#f5365c">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/employees`}
                activeClassName="active"
              >
                Instruktoři
              </NavLink>
            </SidebarItem>

            <SidebarDivider />
            
            <SidebarItem icon="\ec45" iconColor="#11cdef">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/dates`}
                activeClassName="active"
              >
                Termíny
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\ef79" iconColor="#2dce89">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/locations`}
                activeClassName="active"
              >
                Lokality
              </NavLink>
            </SidebarItem>

            <SidebarItem icon="\ee45" iconColor="#f5365c">
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/camps`}
                activeClassName="active"
              >
                Tábory
              </NavLink>
            </SidebarItem>

            <SidebarDivider />

            <SidebarItem icon="\eef1">
              <NavLink
                to={`${process.env.PUBLIC_URL}/user/contacts`}
                activeClassName="active"
              >
                Kontakty
              </NavLink>
            </SidebarItem>
          </>
        );
      default:
        return <></>;
    }
  };

  render() {
    const { userRole } = this.props;

    return (
      <Sidebar>
        <SidebarHeader>
          <p style={{ fontWeight: "900", color: "#212529" }}>
            SPORTJOY | Registrační systém
          </p>
        </SidebarHeader>
        <SidebarNav>{this.renderLinks(userRole)}</SidebarNav>
      </Sidebar>
    );
  }
}

export default SidebarContainer;
