const url = {
  api:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:8888/sportjoy/www/"
      : "https://api.sportjoy.cz/",
  images: "https://api.sportjoy.cz/media/upload/",
  cookies:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "localhost"
      : ".sportjoy.cz",
};

export default url;
